<template>
  <div>
    <base-page-heading title="Fisa client">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Fisa client</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <div class="content">
      <!-- Full Table -->
      <base-block title="Fisa client" v-bind:subtitle="'Numar de articole: '+sheetNumber">
        <Search v-bind:search="search" v-bind:total-page="sheetNumber" />
        <b-table-simple responsive bdataSheeted striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 100%;">Articol</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 10%;">Felul</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 5%;">Seria</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Numarul</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Data</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%">Scadenta</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Facturat</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Achitat</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Sold</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Delegat</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 10%;">Nr. AWB</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="dataSheets.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="10" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="dataSheet in dataSheets" :key="dataSheet.node.id">
              <b-td class="d-sm-none" style="width: 15%;">
                <b-row class="font-size-sm">
                  <b-col class="col-5 mt-2">
                    <div>Tip: <span class="text-dark font-weight-bolder">{{ dataSheet.node.type }}</span></div>
                  </b-col>
                  <b-col class="col-7 mt-2">
                    <div>Seria/Numar: <span class="text-dark font-weight-bolder">{{ dataSheet.node.series }}/{{dataSheet.node.number}}</span></div>
                  </b-col>
                </b-row>
                <b-row class="font-size-sm">
                  <b-col class="col-5 mt-2">
                    <div>Data: <span class="text-dark font-weight-bolder">{{ getDataSheetDate(dataSheet.node.date) }}</span></div>
                  </b-col>
                  <b-col class="col-7 mt-2">
                    <div>Scadenta: <span class="text-dark font-weight-bolder">{{ getDataSheetDate(dataSheet.node.termDate) }}</span></div>
                  </b-col>
                </b-row>
                <b-row class="font-size-sm">
                  <b-col class="col-4 mt-2">
                    <div class="text-center">Facturat:</div>
                  </b-col>
                  <b-col class="col-4 mt-2">
                    <div class="text-center">Achitat:</div>
                  </b-col>
                  <b-col class="col-4 mt-2">
                    <div class="text-center">Sold:</div>
                  </b-col>
                </b-row>
                <b-row class="font-size-sm">
                  <b-col class="col-4">
                    <div class="text-center"><span class="text-primary font-weight-bolder">{{ dataSheet.node.invoiced }}</span></div>
                  </b-col>
                  <b-col class="col-4">
                    <div class="text-center"><span class="text-info font-weight-bolder">{{ dataSheet.node.payed }}</span></div>
                  </b-col>
                  <b-col class="col-4">
                    <div class="text-center"><span class="text-warning font-weight-bolder">{{ dataSheet.node.balance }}</span></div>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col class="col-6 mt-2">
                    <div>Delegat:</div>
                  </b-col>
                  <b-col class="col-6 mt-2 text-center">
                    Nr. AWB:
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-6">
                    <div><span class="text-dark font-w600">{{ dataSheet.node.delegate }}</span></div>
                  </b-col>
                  <b-col class="col-6 text-center">
                    <div><span class="text-dark">{{ dataSheet.node.awb !== '' ? dataSheet.node.awb : '-' }}</span></div>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
                <span class="text-dark font-weight-bolder">{{ dataSheet.node.type }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-dark">{{ dataSheet.node.series }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-dark">{{ dataSheet.node.number }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-dark">{{ getDataSheetDate(dataSheet.node.date) }}</span>
              </b-td>
              <b-td class="bdataSheet-right-0 d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-dark">{{ getDataSheetDate(dataSheet.node.termDate) }}</span>
              </b-td>
              <b-td class="bdataSheet-right-0 d-none d-sm-table-cell text-center font-size-sm">
                <span class="text-primary font-w600">{{ dataSheet.node.invoiced.toFixed(2) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="text-info font-w600">{{ dataSheet.node.payed.toFixed(2) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="text-warning font-w600">{{ dataSheet.node.balance.toFixed(2) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="text-dark font-w600">{{ dataSheet.node.delegate }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm text-center">
                <span class="text-dark">{{ dataSheet.node.awb !== '' ? dataSheet.node.awb : '-' }}</span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import {GET_CLIENT_DATA_SHEET} from "../../../constants/client-data-sheet-graphql";
import InfiniteLoading from "vue-infinite-loading";
import {fleshErrorMessage} from "../../../error/server-error";
import {ITEM_NUMBER_PER_PAGE} from "../../../constants/settings";
import Search from "./partial/Search";
import {formatDate} from "@/constants/helpers/common-helper";

export default {
  name: "ClientDataSheet",
  components: {InfiniteLoading, Search},
  data() {
    return {
      search: (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null,
      dataSheets: [],
      loaded: false,
      pageInfo: null,
      sheetNumber: 0,
    }
  },
  watch: {
    $route() {
      this.dataSheets = [];
      this.pageInfo = null;
      this.loaded = false;
      this.search = (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$refs.infiniteLoading.attemptLoad();
    }
  },
  methods: {
    getDataSheetDate(value) {
      return formatDate(new Date(value));
    },
    loadMore ($state) {
      this.$apollo.query({
        query: GET_CLIENT_DATA_SHEET,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null,
          from: this.getFilterValue('fromDate'),
          to: this.getFilterValue('toDate')
        },
        fetchPolicy: 'no-cache'
      }).then((result) => {
        this.pageInfo = result.data.meUser.clientDataSheet.pageInfo;
        this.dataSheets.push(...result.data.meUser.clientDataSheet.edges);
        this.sheetNumber = result.data.meUser.clientDataSheet.totalCount;
        this.loaded = true;
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getFilterValue(filter)
    {
      if ((this.search && this.search[filter])) {
        return this.search[filter];
      }
    }
  }
}
</script>

<style scoped>

</style>